.customDatePickerWidth {
	display: flex;
	flex-wrap: nowrap;
	flex-direction: row;
	flex: 1 1 0px;
	@include media-breakpoint-down(xs) {
		flex: auto;
	}
}

.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth
	> div
	> div.react-datepicker__input-container
	.customDatePickerWidth
	> div
	> div.react-datepicker__input-container
	input {
	width: 100%;
}

.nav-item {
  .nav-link {
    display: flex;
    align-items: center;
    font-size: 24px;
    transition: ease-in-out 0.3s;

    &.active {
      color: #fff;
      background: rgba(0, 123, 255, .10);
      border-radius: 10px;
      opacity: 1;
    }
  }
}

.sidebar .nav a {
  transition: all 150ms ease-in;
}

.card-header {
  text-transform: uppercase;
}

.badge:empty {
  display: inline-block;
}
.datatable-component{
    .rdt_TableCol{
        font-size: 16px;
    }
    .rdt_TableRow{
        font-size: 14px;
    }
    .p-switch{
        min-width: 25px;
        overflow: visible !important;
        line-height: 13px;
    }
}
/* Modificação da barra de rolagem da pagina */
::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-track {
	// background: #0A264B;
	background: #f5f5f5;
}

::-webkit-scrollbar-thumb {
	// background: #F5F5F5;
	background: #0A264B;
	border-radius: 2px;
}

.my-modal {
	width: 90vw; /* Occupy the 90% of the screen width */
	max-width: 90vw;
}

.modal-open {
	// position: fixed;
	// top: 0;
	// left: 0;
	// width: 100%;
	// height: 100%;
	// background: rgba(0, 0, 0, 0.3);
	// z-index: 2;

	::-webkit-scrollbar {
		width: 8px;
	}

	::-webkit-scrollbar {
		width: 10px;
	}

	::-webkit-scrollbar-track {
		// background: #0A264B;
		background: #f5f5f5;
		border-radius: 15px;
		margin-right: 1px;
	}

	::-webkit-scrollbar-thumb {
		// background: #F5F5F5;
		background: #0A264B;
		border-radius: 15px;
	}
}

#tableContainer {
	table {
		width: 100%;
		border-collapse: collapse;
		thead {
			th {
				background-color: #f5f5f5;
				border: 1px solid #ddd;
				padding: 8px;
				text-align: left;
			}
		}
		tbody {
			tr {
				border-bottom: 1px solid #ddd;
			}
			td {
				border: 1px solid #ddd;
				padding: 8px;
			}
		}
	}
}

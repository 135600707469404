@import '../login-colors';

#login-page {
	background: #0A264B;
	height: 100vh;
	width: 100% !important;
	overflow: hidden;

	.banner {
		position: fixed;
		left: 0;
		right: 0;
		z-index: 1;
		display: block;
		background-image: url('../../assets/img/banners/banner_login.png');
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		// -webkit-filter: blur(2px);
		// -moz-filter: blur(2px);
		// -o-filter: blur(2px);
		// -ms-filter: blur(2px);
		// filter: blur(2px);
		width: 100%;
		height: 100%;
	}

	.content {
		position: fixed;
		left: 0;
		right: 0;
		z-index: 9999;
	}

	.container {
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 100vh;
		width: 100%;
		max-width: 55% !important;
		padding: 0 !important;
		margin: 0 !important;

		.logo {
			position: absolute;
			top: 8rem;
			height: auto;
			transition: width 0.3s ease;
			max-width: 500px;
			width: 100%;

			@media (max-height: 1300px) {
				max-width: 1300px;
				top: 5rem;
				width: 100%;
			}

			@media (max-height: 1200px) {
				max-width: 800px;
				top: 5rem;
				width: 100%;
			}

			@media (max-height: 1000px) {
				max-width: 600px;
				top: 5rem;
				width: 100%;
			}

			@media (max-height: 992px) {
				width: 25%;
			}

			@media (max-height: 768px) {
				width: 20%;
				top: 3rem;
			}

			@media (max-height: 576px) {
				width: 10%;
			}
		}

		.screen {
			display: flex;
			flex-direction: column;
			background-color: #0F3260;
			position: fixed;
			bottom: -5rem;
			max-height: 542px;
			height: 100%;
			max-width: 492px;
			width: 100%;
			margin: 1rem;
			padding: 0 1rem;
			box-shadow: 30px 50px 30px -20px rgba(0, 0, 0, 0.25);
			align-items: center;
			justify-content: center;
			border-radius: 8px;

			.screen__content {
				gap: 60px;
				z-index: 1;
				height: 100%;
				max-width: 412px;
				width: 100%;
				flex: 1;
				box-sizing: border-box;

				form {
					padding-top: 40px;

					button {
						font-size: 1.5rem;
						font-weight: 800;
						margin-top: 25px;
						padding: 16px 20px;
						border-radius: 26px;
						line-height: 27px;
						// text-transform: uppercase;
						font-weight: 700;
						display: flex;
						align-items: left;
						width: 100%;
						height: 68px;
						background-color: #C99444;
						color: white;
						border-radius: 4px;
						cursor: pointer;
						transition: 0.2s;

						&:active,
						&:focus,
						&:hover {
							outline: none;
							border-color: $btn-actions !important;
						}

						>div {
							width: 100%;
							text-align: center;

							>div {
								float: right;
							}
						}
					}

					h5 {
						padding: 15px 0;
						color: #FFFFFF;
						font-size: 1.5rem;
						font-weight: 800;
						line-height: 28.18px;
						font-family: 'raleway', sans-serif;
					}

					.input_container {
						display: flex;
						align-items: center;
						justify-content: center;
					}

					.img-container {
						text-align: center;
						text-shadow: 0px 0px 30px #2758a8;
					}

					.input-group-prepend {
						align-items: center;
						position: relative;
						left: 15px;
					}

					.input-group {
						padding: 20px 0px;
						position: relative;
						background: rgba(201, 76, 76, 0);
						color: #f2f2f2;
						width: 95%;

						.invalid-feedback {
							position: absolute;
							bottom: 0;
						}
					}

					input {
						border: none;
						color: #FFF;
						border-bottom: 2px solid #f2f2f2;
						background: rgba(201, 76, 76, 0);
						padding: 10px;
						padding-left: 24px;
						font-weight: 500;
						transition: 0.2s;
						font-size: 1.5rem;
						line-height: 28.18px;
						font-family: 'raleway', sans-serif;

						&:active,
						&:focus,
						&:hover {
							background: rgba(255, 255, 255, 0.1);
							box-shadow: none;
						}

						&::-webkit-input-placeholder {
							color: #FFF;
						}

						&::after {
							background-color: red;
						}
					}
				}
			}
		}
	}
}
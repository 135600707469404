@import "colors";
// override bootstrap 4 variables

// $blue: #2196f3;
// $indigo: #536dfe;
// $purple: #9c27b0;
// $pink: #ff4081;
// $red: #f44336;
// $orange: #ff9800;
// $yellow: #ffeb3b;
// $green: #4caf50;
// $teal: #009688;
// $cyan: #00bcd4;
// $white: #fff;
$theme-colors: (
  "primary":$color-primary,
  "secondary": $color-secondary,
  "success": $color-success,
  "danger": $color-danger,
  "warning": $color-warning,
  "info": $color-info,
  "colorSelected": $color-selected
);

$pretty--color-primary: $color-primary;
$pretty--color-info: $color-info;
$pretty--color-success: $color-success;
$pretty--color-warning: $color-warning;
$pretty--color-danger: $color-danger;

$enable-gradients: true;

// card
//
$card-cap-bg: #fff;


// sidebar
$cr-sidebar-width: 224px;
